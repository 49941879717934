import { api } from "..";

export const CampaignsService = {
    getCampaigns: (payload: {page: number, orgId?: string}) =>
        api.get(`/public/campaign/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data?.response?.campaigns),

    getMerchantScreenCampaigns: (payload: {page: number, orgId?: string}) =>
        api.get(`/public/campaign/merchant/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data?.response?.campaigns),

    getVerifiedMediaLibrary: (payload: {page: number; orgId: string}) =>
        api.get(`/public/media/list/org/${payload.orgId}/status/VERIFIED/page/${payload.page}`).then((data) => data?.data),

    getUnverifiedMediaLibrary: (payload: {page: number; orgId: string}) =>
        api.get(`/public/media/list/org/${payload.orgId}/status/UN_VERIFIED/page/${payload.page}`).then((data) => data?.data),

    getScreensList: (payload: {page: number, city?: string, state?: string}) =>
        api.get(`/public/screen/state/${payload.state ?? 'ALL'}/city/${payload.city ?? 'ALL'}/page/${payload.page}`).then((data) => data?.data),

    setScreenDefaultmedia: (payload: any) => api.post(`public/campaign/merchant/default`, payload).then((data) => data),

    campaignSaveAsDraft: (payload: any) => api.post('/public/campaign/save', payload).then((data) => data?.data),

    scheduleCampaign: (payload: any) =>
        api.post('/public/campaign/schedule', payload).then((data) => data?.data),

    scheduleCampaignMerchantScreen: (payload: any) =>
        api.post('/public/campaign/merchant/schedule', payload).then((data) => data?.data),
}