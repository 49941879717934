import {createSlice} from '@reduxjs/toolkit';
import {requestStatusEnum} from '../store.types';
import {
    TActionGetCampaignsListPayload,
    TActionGetCampaignsListSuccess,
    TActionGetMediaLibraryPayload,
    TActionGetMediaLibrarySuccess, TActionGetMerchantScreenCampaignsListPayload,
    TActionGetMerchantScreensCampaignsListSuccess, TActionGetScreensListPayload,
    TActionGetScreensListSuccess, TActionSetScreenDefaultMediaPayload,
    TCampaignsReducer
} from './campaigns.types';

const initialState: TCampaignsReducer = {
    campaignsList: {
        data: [],
        status: requestStatusEnum.INITIAL
    },
    merchantScreenCampaignsList: {
        data: [],
        status: requestStatusEnum.INITIAL
    },
    createCampaignPayload: {
        name: '',
        userId: '',
        orgId: '',
        clusters: [],
        slots: [],
        mediaId: '',
        mediaLengthInSec: '',
        startTime: '',
        endTime: '',
    },
    setDefaultMediaStatus: requestStatusEnum.INITIAL,
    createMerchantScreenCampaignPayload: {
        name: '',
        userId: '',
        orgId: '',
        mediaId: '',
        mediaLengthInSec: '',
        screens:[],
        dates:[],
        hours:[]
    },
    campaignMerchantScreenScheduleStatus: requestStatusEnum.INITIAL,
    campaignScheduleStatus: requestStatusEnum.INITIAL,
    campaignSaveAsDraftStatus: requestStatusEnum.INITIAL,
    currentPage: 0,
    mediaLibrary: {
        data: [],
        totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
    SelectedCampaignType: localStorage.getItem('SelectedCampaignType') || '',
    selectedMedia: '',
    selectedMediaLength: 0,
    screensList: {
        data: [],
        totalPages: 0,
        status: requestStatusEnum.INITIAL
    },

    selectedScreens: [],
    selectedDates: [],
    selectedSlots: [],
    selectedClusterSlots: [],
};

const campaignsReducer = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        actionFetchCampaigns(state, { payload }: TActionGetCampaignsListPayload) {
            state.campaignsList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchCampaignsSuccess(state, { payload }: TActionGetCampaignsListSuccess) {
            state.campaignsList.status = requestStatusEnum.SUCCESS;
            state.campaignsList.data = payload.data;
        },
        actionFetchCampaignsFailed(state) {
            state.campaignsList.status = requestStatusEnum.FAILED;
        },
        actionFetchMerchantScreenCampaigns(state, { payload }: TActionGetMerchantScreenCampaignsListPayload) {
            state.merchantScreenCampaignsList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchMerchantScreenCampaignsSuccess(state, { payload }: TActionGetMerchantScreensCampaignsListSuccess) {
            state.merchantScreenCampaignsList.status = requestStatusEnum.SUCCESS;
            state.merchantScreenCampaignsList.data= payload;
        },
        actionFetchMerchantScreenCampaignsFailed(state) {
            state.merchantScreenCampaignsList.status = requestStatusEnum.FAILED;
        },
        actionFetchMediaLibrary(state, { payload }: TActionGetMediaLibraryPayload) {
            state.mediaLibrary.status = requestStatusEnum.PROGRESS;
        },
        actionFetchMediaLibrarySuccess(state, { payload }: TActionGetMediaLibrarySuccess) {
            state.mediaLibrary.status = requestStatusEnum.SUCCESS;
            state.mediaLibrary.data = payload.data;
            state.mediaLibrary.totalPages = payload.totalPages;
        },
        actionFetchMediaLibraryFailed(state) {
            state.mediaLibrary.status = requestStatusEnum.FAILED;
            state.mediaLibrary.data = [];
        },
        actionSetSelectedMedia(state, { payload }) {
            state.selectedMedia = payload?.selectedMedia;
            state.selectedMediaLength = payload?.selectedMediaLength
        },
        actionFetchScreensList(state, { payload }: TActionGetScreensListPayload) {
            state.screensList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchScreensListSuccess(state, { payload }: TActionGetScreensListSuccess) {
            state.screensList.status = requestStatusEnum.SUCCESS;
            state.screensList.data = payload.data;
            state.screensList.totalPages = payload.totalPages;
        },
        actionSetScreenDefaultMedia(state, { payload }: TActionSetScreenDefaultMediaPayload) {
            state.setDefaultMediaStatus = requestStatusEnum.PROGRESS
        },
        actionSetScreenDefaultMediaSucess(state) {
            state.setDefaultMediaStatus = requestStatusEnum.SUCCESS
        },
        actionSetScreenDefaultMediaFailed(state) {
            state.setDefaultMediaStatus = requestStatusEnum.FAILED
        },
        actionFetchScreensListFailed(state) {
            state.screensList.status = requestStatusEnum.FAILED;
            state.screensList.data = [];
        },
        actionSetSelectedScreen(state, { payload }) {
            state.selectedScreens = payload;
        },
        actionSetSelectedDates(state, { payload }) {
            state.selectedDates = payload;
        },
        actionSetSelectedSlots(state, { payload }) {
            state.selectedSlots = payload;
        },
        actionSetSelectedCampaignType(state, { payload }) {
            state.SelectedCampaignType = payload;
            localStorage.setItem('SelectedCampaignType', payload);
        },
        actionAddClusterSlots(state, { payload }){
            state.selectedClusterSlots = payload;
        },
        actionUpdateCreateCampaignPayload(state, { payload }) {
            state.createCampaignPayload = {
                ...state.createCampaignPayload,
                ...payload,
            };
        },
        actionUpdateCreateMerchantScreenCampaignPayload(state, { payload }) {
            state.createMerchantScreenCampaignPayload = {
                ...state.createMerchantScreenCampaignPayload,
                ...payload,
            };
        },
        actionScheduleCampaign(state, {payload}){
            state.campaignScheduleStatus = requestStatusEnum.PROGRESS;
        },
        actionScheduleCampaignSuccess(state, {payload}) {
            state.campaignScheduleStatus = requestStatusEnum.SUCCESS;
        },
        actionScheduleCampaignFailed(state) {
            state.campaignScheduleStatus = requestStatusEnum.FAILED;
        },
        actionScheduleMerchantScreensCampaign(state, {payload}){
            state.campaignMerchantScreenScheduleStatus = requestStatusEnum.PROGRESS;
        },
        actionScheduleMerchantScreensCampaignSuccess(state, {payload}) {
            state.campaignMerchantScreenScheduleStatus = requestStatusEnum.SUCCESS;
        },
        actionScheduleMerchantScreensCampaignFailed(state) {
            state.campaignMerchantScreenScheduleStatus = requestStatusEnum.FAILED;
        },
        actionCampaignSaveAsDraft(state, {payload}){
            state.campaignSaveAsDraftStatus = requestStatusEnum.PROGRESS;
        },
        actionCampaignSaveAsDraftSuccess(state){
            state.campaignSaveAsDraftStatus = requestStatusEnum.SUCCESS;
        },
        actionCampaignSaveAsDraftFailed(state){
            state.campaignSaveAsDraftStatus = requestStatusEnum.FAILED;
        },
        resetCampaigns() {
            localStorage.removeItem('SelectedCampaignType');
            return initialState;
        },
    },
});

export const {
    actionFetchCampaigns,
    actionFetchCampaignsSuccess,
    actionFetchCampaignsFailed,
    actionFetchMediaLibrary,
    actionFetchMediaLibrarySuccess,
    actionFetchMediaLibraryFailed,
    actionSetSelectedMedia,
    actionFetchScreensList,
    actionFetchScreensListSuccess,
    actionFetchScreensListFailed,
    actionSetSelectedScreen,
    actionSetSelectedDates,
    actionSetSelectedSlots,
    actionSetSelectedCampaignType,
    actionAddClusterSlots,
    actionUpdateCreateCampaignPayload,
    actionScheduleCampaign,
    actionScheduleCampaignSuccess,
    actionScheduleCampaignFailed,
    actionScheduleMerchantScreensCampaign,
    actionScheduleMerchantScreensCampaignSuccess,
    actionScheduleMerchantScreensCampaignFailed,
    actionCampaignSaveAsDraft,
    actionCampaignSaveAsDraftSuccess,
    actionCampaignSaveAsDraftFailed,
    actionUpdateCreateMerchantScreenCampaignPayload,
    actionFetchMerchantScreenCampaigns,
    actionFetchMerchantScreenCampaignsSuccess,
    actionFetchMerchantScreenCampaignsFailed,
    actionSetScreenDefaultMedia,
    actionSetScreenDefaultMediaSucess,
    actionSetScreenDefaultMediaFailed,
    resetCampaigns
} = campaignsReducer.actions;

export default campaignsReducer.reducer;
