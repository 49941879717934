import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";
import { requestStatusEnum } from "../../store/store.types";
import { TScreensListData } from "../../store/screens/screens.types";
import styles from "../screens/screens.module.scss";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import Button from "../../components/Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import { Table } from "../../components/Table/Table.component";
import { Modal } from "../../components/Modal";
import { OtpPair } from "../../components/OtpPair";
import {SelectMedia} from "../campaign/CreateCampaign/SelectMedia/SelectMedia.component";
import {ScreensTableColumns} from "./screens.config";
import {actionSetScreenDefaultMedia} from "../../store/campaigns/campaigns.reducer";
import {useLoginSelector} from "../../store/login/useLoginSelectors";

export const ScreensComponent = () => {
    const [tableData, setTableData] = useState<Array<TScreensListData>>([]);
    const [startRecord, setStartRecord] = useState(0);
    const [isAddScreenModalVisible, setIsAddScreenModalVisible] = useState<boolean>(false);
    const [isSelectMediaModalVisible, setIsSelectMediaModalVisible] = useState<boolean>(false);
    const [selectedScreenId, setSelectedScreenId] = useState<string>('');

    const { actionFetchScreensList, actionSetScreenDefaultMedia } = useCampaignsActions();
    const {
        screensList: { data: screensList, status: screensListStatus, totalPages },
        selectedMedia, selectedMediaLength
    } = useCampaignsSelector();
    const { userData } = useLoginSelector();

    useEffect(() => {
        actionFetchScreensList({ page: startRecord });
    }, []);

    useEffect(() => {
        if (screensListStatus === requestStatusEnum.SUCCESS && screensList?.length > 0) {
            setTableData(screensList);
        }
    }, [screensListStatus, screensList]);

    const onNameClick = (name: string) => {
        console.log("name", name);
    };

    const handleAddScreenButton = () => {
        setIsAddScreenModalVisible(true);
    };

    const onCloseAddScreenModal = () => {
        setIsAddScreenModalVisible(false);
    };

    const handleOpenSelectMediaModal = (screenId: number) => {
        console.log('hit');
        setSelectedScreenId(screenId.toString());
        setIsSelectMediaModalVisible(true);
        console.log('setIsSelectMediaModalVisible => ', isSelectMediaModalVisible);
    };
    const handleCloseSelectMediaModal = () => {
        setIsSelectMediaModalVisible(false);
        setSelectedScreenId('');
    };
    const handleSaveSelectedMedia = () => {
        const defaultMediaPayload = {
            screenId: selectedScreenId,
            userId: userData?.userId,
            orgId: userData?.ordId,
            mediaId: selectedMedia,
            mediaLength: selectedMediaLength.toString(),
        };
        actionSetScreenDefaultMedia(defaultMediaPayload);
        console.log(`Saving media ${selectedMedia} for screen ${selectedScreenId}`);
        handleCloseSelectMediaModal();
    };

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        setStartRecord(startRecord);
        actionFetchScreensList({ page: startRecord / recordsPerPage });
    };

    return (
        <div className={styles.screensWrapper}>
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    Screens
                </Text>
            </div>
            {screensListStatus === requestStatusEnum.PROGRESS ? (
                <Spinner />
            ) : (
                <Table
                    data={tableData}
                    columns={ScreensTableColumns(onNameClick, handleOpenSelectMediaModal)}
                    isLoading={false}
                    totalRecords={totalPages * 20}
                    startRecord={startRecord}
                    recordsPerPage={20}
                    onPaginationClick={onPaginationClick}
                    emptyState={"No Screen Found"}
                />
            )}
            <Modal
                isVisible={isAddScreenModalVisible}
                onClose={onCloseAddScreenModal}
                className={styles.modalClass}
                customStyles={{ maxWidth: '500px', maxHeight: '415px' }}
            >
                <OtpPair
                    title={'Add Screens'}
                    subtitle={'Type your pair code, which you see on your screen now'}
                />
            </Modal>
            <Modal
                isVisible={isSelectMediaModalVisible}
                onClose={handleCloseSelectMediaModal}
                className={styles.selectMediaModalClass}
                customStyles={{ maxWidth: '800px', maxHeight: '55vh', minHeight: '55vh' }}
            >
                <div className={styles.selectMediaModalContent}>
                    <Text
                        variant={TextVariantsEnum.Display_xs}
                        fontWeight={FontWeightEnum.Bold}
                    >
                        Select Media
                    </Text>
                    <SelectMedia />
                    <div className={styles.modalActions}>
                        <Button
                            variant={ButtonVariantEnum.secondary}
                            onClick={handleCloseSelectMediaModal}
                            text={'Cancel'}
                        />
                        <Button
                            variant={ButtonVariantEnum.primary}
                            onClick={handleSaveSelectedMedia}
                            text={'Save'}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};
