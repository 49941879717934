import React from 'react';
import styles from './Dropdown.module.scss'
import { SvgIcon } from '../SvgIcon';
import { Text, TextVariantsEnum } from '../Text';

interface DropdownOption {
  label: string;
  value: string;
}

interface DropdownProps {
  options: DropdownOption[];
  placeholder: string;
  value: string | null;
  onChange: (value: string) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({ options, placeholder, value, onChange }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const selectedOption = options.find(option => option.value === value);

  return (
      <div className={styles.dropdown}>
        <div className={styles.dropdown_header} onClick={toggleDropdown}>
          <Text variant={TextVariantsEnum.Text_sm} color={!selectedOption ? 'neutral-50' : 'neutral-70'}>
            {selectedOption ? selectedOption.label : placeholder}
          </Text>
          <SvgIcon src={'icons/arrow.svg'} rotate={isOpen ? "270" : "90"} size={12} />
        </div>
        {isOpen && (
            <ul style={{listStyle: 'none'}} className={styles.dropdown_list}>
              {options.map((option) => (
                  <Text
                      key={option.value}
                      variant={TextVariantsEnum.Text_sm}
                      className={styles.dropdown_list_item}
                      onClick={() => handleOptionClick(option.value)}
                  >
                    {option.label}
                  </Text>
              ))}
            </ul>
        )}
      </div>
  );
};