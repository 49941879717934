import { call, takeLatest, all, put } from "redux-saga/effects";
import {
    actionCampaignSaveAsDraft,
    actionCampaignSaveAsDraftFailed,
    actionCampaignSaveAsDraftSuccess,
    actionFetchCampaigns,
    actionFetchCampaignsFailed,
    actionFetchCampaignsSuccess,
    actionFetchMediaLibrary,
    actionFetchMediaLibraryFailed,
    actionFetchMediaLibrarySuccess,
    actionFetchMerchantScreenCampaigns,
    actionFetchMerchantScreenCampaignsFailed,
    actionFetchMerchantScreenCampaignsSuccess,
    actionFetchScreensList,
    actionFetchScreensListFailed,
    actionFetchScreensListSuccess,
    actionScheduleCampaign,
    actionScheduleCampaignFailed,
    actionScheduleCampaignSuccess,
    actionScheduleMerchantScreensCampaign, actionSetScreenDefaultMedia, actionSetScreenDefaultMediaSucess
} from "./campaigns.reducer";
import { CampaignsService } from "../../services/campaigns/CampaignsService";
import {
    TActionCampaignSaveAsDraft,
    TActionGetCampaignsListPayload,
    TActionGetMediaLibraryPayload, TActionGetMerchantScreenCampaignsListPayload, TActionGetScreensListPayload,
    TActionScheduleCampaignPayload, TActionScheduleMerchantScreenCampaignPayload, TActionSetScreenDefaultMediaPayload
} from "./campaigns.types";
import {useNavigate} from "react-router";
import {routesData} from "../../routing/routesData";
import {routes} from "../../routing/routes";

function* fetchCampaignsList({payload}: TActionGetCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getCampaigns, {page: payload.page, orgId: payload.orgId})
        yield put(actionFetchCampaignsSuccess({data}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchCampaignsFailed())
    }
}

function* fetchMerchantScreenCampaignsList({payload}: TActionGetMerchantScreenCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getMerchantScreenCampaigns, {page: payload.page, orgId: payload.orgId})
        yield put(actionFetchMerchantScreenCampaignsSuccess(data))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMerchantScreenCampaignsFailed())
    }
}

function* campaignSaveAsDraft({payload}: TActionCampaignSaveAsDraft): any {
    try {
        yield call(CampaignsService.campaignSaveAsDraft, payload);
        yield put(actionCampaignSaveAsDraftSuccess());
    } catch (error) {
        console.log('campaign save as draft error: ', error);
        yield put(actionCampaignSaveAsDraftFailed());
    }
}

function* scheduleCampaign({ payload }: TActionScheduleCampaignPayload): any {
    try {
        const data = yield call(CampaignsService.scheduleCampaign, payload);
        yield put(actionScheduleCampaignSuccess({data}));
        console.log('Data => ', data);
    } catch (error) {
        yield put(actionScheduleCampaignFailed());
        console.log('error: ', error);
    }
}

function* scheduleMerchantScreenCampaign({ payload }: TActionScheduleMerchantScreenCampaignPayload): any {
    try {
        const data = yield call(CampaignsService.scheduleCampaignMerchantScreen, payload);
        yield put(actionScheduleCampaignSuccess({data}));
        console.log('Data => ', data);
    } catch (error) {
        yield put(actionScheduleCampaignFailed());
        console.log('error: ', error);
    }
}

function* fetchMediaLibrary({payload}: TActionGetMediaLibraryPayload): any{
    try {
        const data = yield call(CampaignsService.getVerifiedMediaLibrary, payload)
        yield put(actionFetchMediaLibrarySuccess({
            data: data.response.mediaList,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMediaLibraryFailed())
    }
}

function* fetchScreensList({payload}: TActionGetScreensListPayload): any{
    try {
        const data= yield call(CampaignsService.getScreensList, {page: payload.page, state: payload.state, city: payload.city})
        yield put(actionFetchScreensListSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchScreensListFailed())
    }
}

function* setScreenDefaultMedia({payload}: TActionSetScreenDefaultMediaPayload): any{
    try {
        const data= yield call(CampaignsService.setScreenDefaultmedia, payload)
        console.log('Data => ', data);
        yield put(actionSetScreenDefaultMediaSucess());
    } catch (error) {
        console.log(error);
        yield put(actionSetScreenDefaultMediaSucess());
    }
}

export function* campaignsWatcher() {
    yield all([
        takeLatest(actionFetchCampaigns.type, fetchCampaignsList),
        takeLatest(actionFetchMediaLibrary.type, fetchMediaLibrary),
        takeLatest(actionFetchScreensList.type, fetchScreensList),
        takeLatest(actionCampaignSaveAsDraft.type, campaignSaveAsDraft),
        takeLatest(actionScheduleCampaign.type, scheduleCampaign),
        takeLatest(actionScheduleMerchantScreensCampaign.type, scheduleMerchantScreenCampaign),
        takeLatest(actionFetchMerchantScreenCampaigns.type, fetchMerchantScreenCampaignsList),
        takeLatest(actionSetScreenDefaultMedia.type,setScreenDefaultMedia)

    ])
}