// src/components/Form/LoginForm.tsx

import React, { useEffect, useState } from "react";
import { validateEmail, validatePassword } from "../../../../utils/validation";
import Button from "../../../../components/Button/Button";
import styles from "./form.module.scss";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../../../components/Button/button.types";
import { Input } from "../../../../components/Input";
import { Text, TextVariantsEnum } from "../../../../components/Text";
import { useLoginActions } from "../../../../store/login/useLoginActions";
import { useLoginSelector } from "../../../../store/login/useLoginSelectors";
import { requestStatusEnum } from "../../../../store/store.types";
import { TRUE } from "sass";

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [hasError, setHasError] = useState(false)


  const {actionSubmitLogin} = useLoginActions()
  const {form:{status}} = useLoginSelector()


  useEffect(() => {
    if (!validateEmail(email) || !validatePassword(password)) {
      console.log("Email validation error");
      setHasError(true)
    }else setHasError(false)
  },[email, password])

  const handleLoginClick = () => {
    if (hasError) {
      return;
    }
    actionSubmitLogin({email, password})
  };

  return (
    <div className={styles.form}>
      <div>
      <Input
        type="email"
        placeholder="Email/Username"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        width="440px"
      />
      {email && !validateEmail(email) && (
        <Text
        variant = {TextVariantsEnum.Text_xs}
        color="error"
  >
          Invalid email format</Text>
      )}
      </div>
      <div className={styles.passwordField}>
        <Input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          width="440px"
          rightBlock={
            <Button
              icon={"./icons/eye_open.svg"}
              onClick={() => setShowPassword(!showPassword)}
              variant={ButtonVariantEnum.secondary}
              size={ButtonTextSizeEnum.md}
              className={styles.passwordIcon}
            />
          }
        />
          {password && !validatePassword(password) && (
            <Text
            variant = {TextVariantsEnum.Text_xs}
            color="error"
            className={styles.error}>
              Password must be at least 8 characters long.
            </Text>
          )}
      </div>
    
      <div className={styles.rem_forgot}>
        {/* <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          Remember me
        </label> */}
        <Text
          variant={TextVariantsEnum.Text_sm}
          onClick={() => console.log("forgot password")}
        >
          Forgot Password?
        </Text>
      </div>
      <Button
        text={status === requestStatusEnum.PROGRESS ? '' :"Log In"}
        onClick={handleLoginClick}
        variant={ButtonVariantEnum.primary}
        size={ButtonTextSizeEnum.md}
        width="440px"
        disabled={!(email && password) || hasError}
        loading={status === requestStatusEnum.PROGRESS}
        isLogin= {true}
      />
    </div>
  );
};
