import { Checkbox } from "../../../../components/Checkbox/Checkbox.component";
import { StatusBadge } from "../../../../components/StatusBadge/StatusBadge.component";
import { columnConfigType } from "../../../../components/Table/Table.types";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../../../components/Text";
import { capitalize } from "../../../../utils/capitalize";
import styles from './SelectScreenCluster.module.scss';

interface TableColumnProps {
    isScreen: boolean;
    onNameClick: (name: string) => void;
    onSelectItem: (id: string, name: string) => void;
    selectedItems: string[];
}

export const getTableColumns = ({ isScreen, onNameClick, onSelectItem, selectedItems }: TableColumnProps): columnConfigType<any>[] => {
    const columns: columnConfigType<any>[] = [
        {
            id: 'name',
            name: isScreen ? 'Screen Name' : 'Cluster Name',
            cellRenderer: (data) => (
                <div className={styles.cellRenderer}>
                    <Checkbox
                        checked={selectedItems.includes(data?.name)}
                        id={`table-header-checkbox-${data.id}`}
                        onClick={() => onSelectItem(data.id, data.name)}
                    />
                    <Text
                        variant={TextVariantsEnum.Text_md}
                        fontWeight={FontWeightEnum.Normal}
                        color={'neutral-70'}
                        onClick={() => onNameClick(data.name)}
                    >
                        {capitalize(data.name)}
                    </Text>
                </div>
            )
        }
    ];

    if (isScreen) {
        columns.push(
            {
                id: 'status',
                name: 'Status',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            <StatusBadge status={data?.status?.toLowerCase()} />
                        </Text>
                    </div>
                )
            },
            {
                id: 'location',
                name: 'Location',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {data.location}
                        </Text>
                    </div>
                )
            },
            {
                id: 'tags',
                name: 'Tags',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {data.tags.join(', ')}
                        </Text>
                    </div>
                )
            },
            {
                id: 'timing',
                name: 'Timing',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {data.timing}
                        </Text>
                    </div>
                )
            }
        );
    } else {
        columns.push(
            {
                id: 'total_screens',
                name: 'Total Screens',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {data.totalScreens}
                        </Text>
                    </div>
                )
            }
        );
    }

    return columns;
};
