import React from "react";
import Button from "../../../../components/Button/Button";
import {
    ButtonTextSizeEnum,
    ButtonVariantEnum,
} from "../../../../components/Button/button.types";
import { Dropdown } from "../../../../components/Dropdown/Dropdown.component";
import {
    FontWeightEnum,
    Text,
    TextVariantsEnum,
} from "../../../../components/Text";
import styles from "./CreateClusterFilter.module.scss";
import { useClustersActions } from "../../../../store/cluster/useClusterActions";
import { useClustersSelector } from "../../../../store/cluster/useClusterSelector";

interface CreateClusterFilterProps {
    applyFilters: () => void;
}

export const CreateClusterFilter: React.FC<CreateClusterFilterProps> = ({
                                                                            applyFilters,
                                                                        }) => {
    const { actionSetSelectedCity, actionSetSelectedState } = useClustersActions();
    const { selectedState, selectedCity } = useClustersSelector();

    const stateOptions = [
        { label: "Uttar Pradesh", value: "UP" },
        { label: "Haryana", value: "HR" },
        { label: "Karnataka", value: "KA" },
    ];

    const cityOptions = [
        { label: "Meerut", value: "MRT" },
        { label: "SRE", value: "SRE" },
        { label: "Bangalore", value: "BLR" },
    ];

    const handleStateChange = (value: string) => {
        actionSetSelectedState(value);
    };

    const handleCityChange = (value: string) => {
        actionSetSelectedCity(value);
    };

    return (
        <div className={styles.filterWrapper}>
            <div className={styles.filters}>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                >
                    Filters
                </Text>
                <Dropdown
                    options={stateOptions}
                    placeholder="State"
                    value={selectedState}
                    onChange={handleStateChange}
                />
                <Dropdown
                    options={cityOptions}
                    placeholder="City"
                    value={selectedCity}
                    onChange={handleCityChange}
                />
            </div>
            <Button
                iconSize={12}
                text={"Apply"}
                onClick={applyFilters}
                variant={ButtonVariantEnum.primary}
                size={ButtonTextSizeEnum.md}
                className={styles.applyBtn}
            />
        </div>
    );
};