import React from 'react';
import Button from "../Button/Button";
import {ButtonTextSizeEnum, ButtonVariantEnum} from "../Button/button.types";
import styles from "./toggleSwitch.module.scss";

const ToggleSwitch = ({ options, selectedOption, onChange }: any) => {
    return (
        <div className={styles.toggleSwitchWrapper}>
            {options.map((option: any) => (
                <Button
                    text={option}
                    className={styles.toggleButtonClassWrapper}
                    onClick={() => onChange(option)}
                    variant={ButtonVariantEnum.secondary}
                    size={ButtonTextSizeEnum.md}
                />
            ))}
        </div>
    );
};

export default ToggleSwitch;