import { columnConfigType } from "../../components/Table/Table.types"
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text"
import { StatusBadge } from "../../components/StatusBadge/StatusBadge.component"
import { TCampaignListData, TMerchantScreenCampaignListData } from "../../store/campaigns/campaigns.types"
import { capitalize } from "../../utils/capitalize"
import { dateFormatter } from "../../utils/dateFormatter"

type CombinedCampaignData = TCampaignListData | TMerchantScreenCampaignListData;

export const CampaignsTableColumns = (
    onNameClick: (name: string) => void
): columnConfigType<CombinedCampaignData>[] => [
    {
        id: 'campaign_name',
        name: 'Campaign Name',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color={'neutral-70'}
                    onClick={() => onNameClick(data.name)}
                >
                    {capitalize(data.name)}
                </Text>
            </div>
        )
    },
    {
        id: 'minutes_played',
        name: 'Minutes Played',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                >
                    {(data as TCampaignListData)?.playTime || 'N/A'}
                </Text>
            </div>
        )
    },
    {
        id: 'status',
        name: 'Status',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    <StatusBadge status={data.status.toLowerCase()} />
                </Text>
            </div>
        )
    },
    {
        id: 'created_at',
        name: 'Created At',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {dateFormatter((data as TCampaignListData).id)}
                </Text>
            </div>
        )
    },
]

export const MerchantScreenCampaignsTableColumns = (
    onNameClick: (name: string) => void
): columnConfigType<CombinedCampaignData>[] => [
    {
        id: 'name',
        name: 'Campaign Name',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color={'neutral-70'}
                    onClick={() => onNameClick(data.name)}
                >
                    {capitalize(data.name)}
                </Text>
            </div>
        )
    },
    {
        id: 'slots',
        name: 'Slots',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                >
                    {(data as TMerchantScreenCampaignListData).slots || 'N/A'}
                </Text>
            </div>
        )
    },
    {
        id: 'status',
        name: 'Status',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    <StatusBadge status={data.status.toLowerCase()} />
                </Text>
            </div>
        )
    },
    {
        id: 'totalScreens',
        name: 'Total Screens',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.totalScreens}
                </Text>
            </div>
        )
    },
]