import React, { useEffect, useState } from 'react';
import TimeSlot from './TimeSlot.component';
import styles from './EventCalenderLarge.module.scss';
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";

const hours = [
    "08 AM - 09 AM",
    "09 AM - 10 AM",
    "10 AM - 11 AM",
    "11 AM - 12 PM",
    "12 PM - 01 PM",
    "01 PM - 02 PM",
    "02 PM - 03 PM",
    "03 PM - 04 PM",
    "04 PM - 05 PM",
    "05 PM - 06 PM",
    "06 PM - 07 PM",
    "07 PM - 08 PM",
    "08 PM - 09 PM",
    "09 PM - 10 PM",
    "10 PM - 11 PM",
];

const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
};

const getFormattedDate = (date: Date): string => {
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
};

const generateDates = (): string[] => {
    const indiaTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const currentDate = new Date(indiaTime);
    currentDate.setDate(currentDate.getDate() + 1); // Start from tomorrow
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);

    const dates: string[] = [];
    for (let i = 0; i < 30; i++) { // Generate dates for the next 30 days
        const date = new Date(year, month, currentDate.getDate() + i);
        dates.push(getFormattedDate(date));
    }

    return dates;
};

const EventCalenderLarge: React.FC = () => {
    const { actionAddClusterSlots } = useCampaignsActions();
    const [selectedTimestamps, setSelectedTimestamps] = useState<string[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [dates, setDates] = useState<string[]>([]);

    useEffect(() => {
        setDates(generateDates());
    }, []);

    const handleSelect = (row: string, column: string) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set to beginning of the day

        setSelectedTimestamps(prevTimestamps => {
            const [day, month] = row.split(' ').slice(0, 2);
            const [startHour, endHour] = column.split(' ')[0].split(':');
            const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);

            // Check if the selected date is in the future
            if (date > currentDate) {
                const timestamp = (date.getTime() / 1000).toString();
                const isSelected = prevTimestamps.includes(timestamp);
                const newTimestamps = isSelected
                    ? prevTimestamps.filter(ts => ts !== timestamp)
                    : [...prevTimestamps, timestamp];

                return newTimestamps;
            }

            return prevTimestamps; // Return unchanged if date is not in the future
        });
    };

    const handleMouseDown = (row: string, column: string) => {
        setIsDragging(true);
        handleSelect(row, column);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseEnter = (row: string, column: string) => {
        if (isDragging) {
            handleSelect(row, column);
        }
    };

    const isCellSelected = (row: string, column: string) => {
        const [day, month] = row.split(' ').slice(0, 2);
        const [startHour, endHour] = column.split(' ')[0].split(':');
        const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);
        const timestamp = (date.getTime() / 1000).toString();
        return selectedTimestamps.includes(timestamp);
    };

    useEffect(() => {
        actionAddClusterSlots(selectedTimestamps);
    }, [selectedTimestamps]);

    return (
        <div
            className={styles.board}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <div className={styles.times}>
                {[""].concat(hours).map((hour, index) => (
                    <div
                        key={index}
                        className={`${styles.timeSlot} ${index === 0 ? styles.firstTimeSlot : ''}`}
                    >
                        {hour}
                    </div>
                ))}
            </div>
            <div className={styles.grid}>
                {dates.map((date, dateIndex) => (
                    <div key={dateIndex} className={styles.column}>
                        <div className={styles.date}>{date.split(' ')[0]} {date.split(' ')[1]}</div>
                        {hours.map((hour, hourIndex) => (
                            <TimeSlot
                                key={hourIndex}
                                row={date}
                                column={hour}
                                isSelected={isCellSelected(date, hour)}
                                onSelect={() => handleSelect(date, hour)}
                                onMouseDown={() => handleMouseDown(date, hour)}
                                onMouseEnter={() => handleMouseEnter(date, hour)}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventCalenderLarge;